import React from 'react';
import cls from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import { formatReviews } from '../formatters/review';
import Link from './Link';
import Stars from './Stars';
import ExternalLink from './ExternalLink';
import styles from './ReviewTable.module.scss';

const ReviewTable = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          frontmatter: { template: { eq: "review" }, hidden: { ne: true } }
        }
        sort: { order: DESC, fields: [frontmatter___score] }
      ) {
        nodes {
          ...ReviewFragment
        }
      }
    }
  `);

  return (
    <div className={styles.root}>
      <div
        className={cls(
          'box columns is-hidden-mobile has-text-white has-text-weight-bold',
          styles.header,
        )}
      >
        <div className="column is-2-desktop is-size-7 is-3-tablet heading is-marginless">
          Decoder
        </div>
        <div className="column is-6-desktop is-size-7 heading is-marginless">
          Summary
        </div>
        <div className="column is-2-desktop is-size-7 is-2-tablet heading has-text-centered is-marginless">
          Score
        </div>
      </div>
      {formatReviews(data).map((review, index) => (
        <div
          key={review.id}
          className={cls(
            'box columns is-vcentered has-text-weight-bold item',
            styles.item,
            review.slug,
          )}
        >
          <div className="column is-2-desktop is-3-tablet">
            <div className="columns is-centered is-multiline is-marginless is-paddingless">
              <span
                className={cls('tag is-medium is-primary', styles.position)}
              >
                #{index + 1}
              </span>
              <Link
                to={review.path}
                className={cls('column is-full is-paddingless', styles.logo)}
              >
                <img src={review.logo} alt={review.title} />
              </Link>
              <div
                className={cls(
                  'column is-full is-paddingless is-hidden-desktop',
                  styles.actions,
                )}
              >
                <ExternalLink
                  className={cls(
                    'button is-primary is-small has-text-weight-bold',
                    styles.action,
                    styles.visit,
                  )}
                  href={review.url}
                >
                  Visit Site
                </ExternalLink>
              </div>
            </div>
          </div>
          <div className="column is-6-desktop">
            <div
              className={cls('is-size-7', styles.description)}
              dangerouslySetInnerHTML={{ __html: review.description }}
            />
          </div>
          <div className="column is-2-desktop is-2-tablet has-text-centered">
            <p className="title is-marginless has-text-centered">
              {review.score.toFixed(1)}
            </p>
            <div className={styles.stars}>
              <Stars score={review.score} />
            </div>
            <div className={cls(styles.review)}>
              <Link
                className={cls(
                  'button is-small is-size-7 has-text-weight-bold',
                  styles.action,
                )}
                to={review.path}
              >
                Read review
              </Link>
            </div>
          </div>
          <div
            className={cls(
              'column is-2-desktop has-text-centered is-hidden-touch',
              styles.actions,
            )}
          >
            <ExternalLink
              className={cls(
                'button is-primary is-size-6  has-text-weight-bold is-fullwidth',
                styles.action,
              )}
              href={review.url}
            >
              Visit Site
            </ExternalLink>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ReviewTable;
