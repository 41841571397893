import React from 'react';
import cls from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import { formatReviews } from '../formatters/review';
import { levelHeadings } from '../formatters/html';
import Link from './Link';
import ExternalLink from './ExternalLink';
import styles from './ReviewSummaries.module.scss';

const ReviewSummaries = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          frontmatter: { template: { eq: "review" }, hidden: { ne: true } }
        }
        sort: { order: DESC, fields: [frontmatter___score] }
        limit: 3
      ) {
        nodes {
          ...ReviewFragment
        }
      }
    }
  `);

  return formatReviews(data).map(review => {
    const summary = levelHeadings(review.summary, 3)
      .replace(
        /(<h\d>Pros<\/h\d>[\s\S]*?)(<ul>)([\s\S]+?<\/ul>)/,
        `<div class="${cls('columns is-marginless')}"><div>$1<ul class="${cls(
          styles.list,
          styles.listPros,
        )}">$3</div>`,
      )
      .replace(
        /(<h\d>Cons<\/h\d>[\s\S]*?)(<ul>)([\s\S]+?<\/ul>)/,
        `<div>$1<ul class="${cls(
          styles.list,
          styles.listCons,
        )}">$3</div></div>`,
      );
    return (
      <div key={review.id} className={cls('box', review.slug)}>
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <Link to={review.path} className={styles.logo}>
                <img src={review.logo} alt={review.title} />
              </Link>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <ExternalLink
                className={cls(
                  'button is-primary is-small has-text-weight-bold',
                  styles.action,
                  styles.visit,
                )}
                href={review.url}
              >
                Visit Site
              </ExternalLink>
            </div>
          </div>
        </div>
        <div dangerouslySetInnerHTML={{ __html: summary }} />
        <div className={styles.readMoreWrapper}>
          <Link className="button is-light" to={review.path}>
            Read full review →
          </Link>
        </div>
      </div>
    );
  });
};

export default ReviewSummaries;
