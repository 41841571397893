import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cls from 'classnames';
import { formatArticles } from '../formatters/article';
import Link from './Link';
import AspectRatioImage from './AspectRatioImage';
import styles from './ArticleGrid.module.scss';

const ArticleGrid = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          frontmatter: { template: { eq: "article" }, hidden: { ne: true } }
        }
        sort: { order: DESC, fields: [frontmatter___date] }
      ) {
        nodes {
          ...ArticleFragment
        }
      }
    }
  `);

  return (
    <div className={styles.root}>
      <div className="columns is-multiline">
        {formatArticles(data).map(article => (
          <div key={article.id} className="column is-half">
            <div className="box">
              <div className="columns">
                <div className="column is-one-third">
                  <AspectRatioImage ratio={3 / 2} src={article.image} />
                </div>
                <div className="column is-two-thirds">
                  <div className={styles.details}>
                    <Link to={article.path}>{article.title}</Link>
                    <div
                      className={cls('is-hidden-touch', styles.readMoreWrapper)}
                    >
                      <Link className="button is-small" to={article.path}>
                        Read more →
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="has-text-centered">
        <Link className="button is-light" to="/articles">
          View all articles
        </Link>
      </div>
    </div>
  );
};

export default ArticleGrid;
