import React from 'react';
import cls from 'classnames';
import Layout from '../components/Layout';
import Meta from '../components/Meta';
import Section from '../components/Section';
import ReviewTable from '../components/ReviewTable';
import ReviewSummaries from '../components/ReviewSummaries';
import ArticleGrid from '../components/ArticleGrid';
import devicesUrl from '../images/banner-devices.png';
import styles from './index.module.scss';

const IndexPage = () => {
  return (
    <Layout>
      <Meta title="Which VIN decoder to choose? find best one" />
      <section className="hero is-light is-bold">
        <div className={cls('hero-body', styles.hero)}>
          <div className="container">
            <div className="columns is-vcentered is-variable is-8 is-desktop">
              <div className="column is-8 is-12-touch">
                <h1 className="title">Best VIN decoders {new Date().getFullYear()}</h1>
                <h2 className="subtitle">
                  Always check used car before buying it
                </h2>
                <p>
                  Using a <strong>high-quality VIN decoder</strong> is the best
                  way to find out the full history of the vehicle. It’s the
                  easiest way to{' '}
                  <strong>uncover mileage fraud and accidents</strong>. Here are
                  the top VIN checkers which provide reliable vehicle history
                  reports.
                </p>
              </div>
              <div className={cls('column is-4', styles.devices)}>
                <img
                  className={styles.devicesImage}
                  src={devicesUrl}
                  alt="Top 3 VIN Decoders"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Section>
        <ReviewTable />
      </Section>
      <Section>
        <Title>Must Reads</Title>
        <ArticleGrid />
      </Section>
      <Section>
        <Subtitle>What is a VIN decoder?</Subtitle>
        <p>
          Basically, VIN decoder is a software to decode the car data that has
          been encoded at the factory. However, top VIN decoders nowadays
          provide much more services.
        </p>
        <p>
          From manufacturing day to the issue of salvage title or scrapping,
          everything that happens to the particular vehicle is being recorded in
          various registries and databases using the VIN number. So, all this
          data can be found and conveyed via a car history report generated by
          quality VIN decoder.
        </p>
        <Subtitle>What Are the Benefits of VIN Decoder Data?</Subtitle>
        <p>
          Frankly speaking, VIN check can tell everything that has ever been
          documented about the vehicle.
        </p>
        <ul>
          <li>
            Basic facts: make, model, production country and date, engine power,
            body, fuel or transmission type, etc.
          </li>
          <li>
            Vehicle’s history: genuine mileage, if it had any accidents, if it
            had been stolen, information about technical inspections or previous
            owners, registration countries, etc.
          </li>
          <li>Photos from various periods.</li>
          <li>
            Information about the model: equipment installed in the factory,
            common model bugs, etc.
          </li>
          <li>
            Some VIN decoders offer their unique features, for example,
            vehicle’s market price evaluation.
          </li>
        </ul>
        <Subtitle>How Does VIN Decoder Help Me to Buy a Car?</Subtitle>
        <p>
          Used car sellers often don’t want to disclose important facts to the
          buyers. For example, it’s estimated that at least 30–50% second-hand
          cars are being sold with odometer fraud. In the USA, 1 of 6 used cars
          listed for sale has been in an accident.
        </p>
        <p>
          Lots of defects or bugs aren’t easy to notice when test-driving or
          visually inspecting the car. Moreover, many important details you
          probably wouldn’t be able to find without dedicating long hours for
          research. There are some things you wouldn’t find at all unless you’re
          a trained expert.
        </p>
        <p>
          Meanwhile, VIN decoder can provide detailed information almost
          instantly and reveal what seller might want to hide from you: clocked
          mileage, concealed accidents, hidden bugs, etc.
        </p>
        <Subtitle>Free or Paid VIN Decoder?</Subtitle>
        <p>
          From a consumer perspective, there are two types of car history
          reports: free (in most cases give only basic data) and paid ones
          (include entire vehicle’s history and unique features). Free reports
          often tell only basic facts which you can see in the ad. Meanwhile,
          paid vehicle history reports can provide with the full available data
          which might be crucial for your decision whether to buy this vehicle.
        </p>
        <Subtitle>Is VIN Decoder Useful for My Business?</Subtitle>
        <p>
          There can be two answers, and both are YES! If you work in the car
          reselling business, you definitely need to know detailed information
          about the vehicles you’re buying. VIN checks often offer special
          conditions for B2B clients.
        </p>
        <p>
          On the other hand, if you are a car seller, showing a vehicle history
          report builds trust in potential buyers eyes.
        </p>
      </Section>
      <Section>
        <Title>Read More About the Best VIN Decoders</Title>
        <ReviewSummaries />
      </Section>
      <Section>
        <Title>Questions & Answers</Title>
        <Subtitle>What Is VIN?</Subtitle>
        <p>
          Sometimes it’s also called VIN number, VIN code or a vehicle body
          number. But it’s always the same VIN (short for <strong>V</strong>
          ehicle <strong>I</strong>dentification <strong>N</strong>umber), a
          unique code assigned to every vehicle that can be found either on its
          body or in the documents. From 1981, all on-road vehicles must have
          17-character-long VIN, which lets to identify an exact car, commercial
          vehicle, motorcycle, bus, truck, trailer, etc.
        </p>
        <Subtitle>What Is a Car History Report?</Subtitle>
        <p>
          Vehicle’s history report is structured information provided by VIN
          decoders to their users. Usually, they tell all the data the VIN
          checking platform was able to find out from its sources.
        </p>
        <Subtitle>Where Do VIN Lookups Take Information From?</Subtitle>
        <p>
          It depends on the VIN checking platform. However, they often use both
          national, private or international institutions registries, car
          dealers, car auctions, insurance, leasing databases, various paid
          APIs, etc.
        </p>
      </Section>
    </Layout>
  );

  function Title({ children }) {
    return <h3 className="title">{children}</h3>;
  }
  function Subtitle({ children }) {
    return <h4 className="is-size-5 has-text-grey-dark">{children}</h4>;
  }
};

export default IndexPage;
